
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllContext } from '../../AllContext';
import { fetchUrl } from '../../config';
import Loading from '../Loading/Loading';
import SideNav from '../SideNav/SideNav';
// import styles from './EditOrder.module.css'

function OrderCreate() {
    const { setShowAlert, setAlertText, setAlertVariant } = useContext(AllContext)
    const [isLoading, setIsLoading] = useState(false)
    const route = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const [inputValue, setInputvalue] = useState({
        name: '',
        phone: '',
        address: '',
        product: '',
        size: '',
        color: '',
        quantity: '',
        price: '',
        delivery: '',
        total:'',
        note: '',

    })
    useEffect(() => {
        const totalPrice = parseFloat(inputValue.price) || 0;
        const deliveryCost = parseFloat(inputValue.delivery) || 0;
        setInputvalue((prevState) => ({
            ...prevState,
            total: totalPrice + deliveryCost,
        }));
    }, [inputValue.price, inputValue.delivery]);

    const handelInput = (action, e) => {
        const newInput = { ...inputValue }
        if (e.target.name === action)
            newInput[e.target.name] = e.target.value
        setInputvalue(newInput)
    }


    const handleUpdateOrderStatus = (e) => {
        e.preventDefault()
        setIsLoading(true)
        const orderStatus = new FormData()
        orderStatus.append('name', inputValue.name)
        orderStatus.append('phone', inputValue.phone)
        orderStatus.append('address', inputValue.address)
        orderStatus.append('products', inputValue.product)
        orderStatus.append('size', inputValue.size)
        orderStatus.append('color', inputValue.color)
        orderStatus.append('price', inputValue.price)
        orderStatus.append('quantity', inputValue.quantity)
        orderStatus.append('delivery_fee', inputValue.delivery)
        orderStatus.append('total_amount', inputValue.total)
        orderStatus.append('note', inputValue.note)
        fetch(`${fetchUrl}/api/order/create/`, {
            headers: { "Authorization": `Bearer ${accessToken}` },
            method: "POST",
            body: orderStatus

        })
            .then(res => {
                setIsLoading(false)
                if (res.ok) {
                    route(0)
                    setShowAlert(true)
                    setAlertText(` Create Success`)
                    setAlertVariant('success')
                }
                else {
                    setIsLoading(false)
                    setShowAlert(true)
                    setAlertText(`Fail to update`)
                    setAlertVariant('danger')
                }

            })
            .catch(() => {
                setIsLoading(false)
                setShowAlert(true)
                setAlertText(`Fail to update`)
                setAlertVariant('danger')
            })
    }
    return (
        <div className='NavRow'>
            <div>
                <SideNav />
            </div>
            <div className='bodyLayout'>
                <div>
                    <div>
                        {isLoading ? <Loading /> : null}
                        <div>

                            <div>

                                <div className='py-5'>
                                    <h4 className='text-center text-bg-dark'> New Order</h4>
                                    <form onSubmit={handleUpdateOrderStatus}>
                                        <div className=' col-md-6 mx-auto'>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Name</label>
                                                <input onChange={(e) => handelInput('name', e)} value={inputValue.name} type="text" name="name" placeholder='Name' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Phone</label>
                                                <input onChange={(e) => handelInput('phone', e)} value={inputValue.phone} type="text" name="phone" placeholder='Phone' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Address</label>
                                                <input onChange={(e) => handelInput('address', e)} value={inputValue.address} type="text" name="address" placeholder='Address' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Product</label>
                                                <input onChange={(e) => handelInput('product', e)} value={inputValue.product} type="text" name="product" placeholder='Product' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Size</label>
                                                <input onChange={(e) => handelInput('size', e)} value={inputValue.size} type="text" name="size" placeholder='Size' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Color</label>
                                                <input onChange={(e) => handelInput('color', e)} value={inputValue.color} type="text" name="color" placeholder='Color' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Quantity</label>
                                                <input onChange={(e) => handelInput('quantity', e)} value={inputValue.quantity} type="number" name="quantity" placeholder='Quantity' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Price</label>
                                                <input onChange={(e) => handelInput('price', e)} value={inputValue.price} type="number" name="price" placeholder='Price Per Item' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Delivery Fee</label>
                                                <input onChange={(e) => handelInput('delivery', e)} value={inputValue.delivery} type="number" name="delivery" placeholder='Delivery Fee' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Total Price</label>
                                                <input disabled onChange={(e) => handelInput('total', e)} value={inputValue.total} type="number" name="total" placeholder='Total Price' className='form-control mt-2' />
                                            </div>
                                            <div className='mb-3 mt-5'>
                                                <label htmlFor='name'>Note</label>
                                                <input onChange={(e) => handelInput('note', e)} value={inputValue.note} type="text" name="note" placeholder='Note' className='form-control mt-2' />
                                            </div>
                                            <input className='my-3 btn btn-primary w-100' type="submit" value="Create"  />
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default OrderCreate;